<template>
	<div class="content">
		<div id="mapboxMap"></div>
	</div>
</template>

<script>
import mapboxgl from 'mapbox-gl';
import mapobj from '@/utils/mapw.js';
import { request } from '@/api/basic';
import * as turf from '@turf/turf';
import spriteJson from '@/assets/sprite/sprite-wind';
import { getToken } from '@/api/cookies';

let line_ID = 'layer_route_recommendations_line';
let point_ID = 'layer_point';
let lstIconLayerId = 'lstIconLayerId';
export default {
	data() {
		return {};
	},
	map: null,
	mounted() {
		this.init();
	},
	methods: {
		// 初始化地图
		init() {
			mapboxgl.accessToken =
				'pk.eyJ1IjoibGl0dGxlZml2ZTE5OTUiLCJhIjoiY2w1OWRtdTB3MmJqbTNjcXFpcWE4dDIxdSJ9.3ZH5BCZI085b4B9XVflZpg'; // 这里请换成自己的token
			// eslint-disable-next-line no-unused-vars
			this.map = new mapboxgl.Map({
				container: 'mapboxMap', // container id 绑定的组件的id
				style: 'mapbox://styles/littlefive1995/clpasntyo002t01re90w03axq',
				center: [105.78, 32.99],
				zoom: 1, // starting zoom 地图初始的层级
				pitch: 0, // 地图的角度，不写默认是0，取值是0-60度，一般在3D中使用
				bearing: 0, // 地图的初始方向，值是北的逆时针度数，默认是0，即是正北
				preserveDrawingBuffer: true, // 显式设置 preserveDrawingBuffer 为 true, 必备
			});

			// 等待地图加载完成
			this.map.on('load', () => {
				this.initSprite();
				// 加载自定义图标
				let array = ['aisnoservice', 'goodais'];
				array.forEach((element) => {
					this.map.loadImage(
						require('../../../../assets/img/' + element + '.png'), // 图标 URL
						(error, image) => {
							if (error) throw error;
							// 将图标添加到地图资源中
							this.map.addImage(element, image);
						},
					);
				});
				//this.$parent.$parent.loading = false
				let layers = this.map.getStyle();
				//console.log("🚀 ~ file: index.vue:58 ~ this.map.on ~ layers:", layers)
				// 添加一个面图层，并设置颜色为纯色
				this.map.addLayer(
					{
						id: 'background-layer',
						type: 'fill',
						source: {
							type: 'geojson',
							data: {
								type: 'Feature',
								geometry: {
									type: 'Polygon',
									coordinates: [
										[
											[-180, -90],
											[-180, 90],
											[180, 90],
											[180, -90],
											[-180, -90],
										],
									],
								},
							},
						},
						paint: {
							'fill-color': '#dddddd', // 设置纯色填充颜色
						},
					},
					'eca-zones-polygon',
				); // 将图层添加到'waterway-label'图层之前
				//
			});
		},
		initSprite() {
			let that = this;
			var img = new Image();
			img.onload = function () {
				Object.keys(spriteJson).forEach((key) => {
					let spriteItem = spriteJson[key];
					let { x, y, width, height } = spriteItem;
					let canvas = that.createCavans(width, height);
					let context = canvas.getContext('2d');
					context.drawImage(img, x, y, width, height, 0, 0, width, height);
					// 单位雪碧图项，转base64字符串
					let base64Url = canvas.toDataURL('image/png');
					that.map.loadImage(base64Url, (error, simg) => {
						if (!that.map.hasImage(key)) {
							that.map.addImage(key, simg);
						}
					});
				});
			};
			img.crossOrigin = 'anonymous';
			img.src = require('../../../../assets/sprite/sprite.png');
		},
		createCavans(width, height) {
			var canvas = document.createElement('canvas');
			canvas.width = width;
			canvas.height = height;
			return canvas;
		},
		wind_direction_img(speed) {
			var windLevel = [
				[-9999999, 0],
				[1, 2],
				[3, 7],
				[8, 12],
				[13, 17],
				[18, 22],
				[23, 27],
				[28, 32],
				[33, 37],
				[38, 42],
				[43, 47],
				[48, 52],
				[53, 57],
				[58, 62],
				[63, 67],
				[68, 72],
				[73, 77],
				[78, 107],
				[108, 122],
				[123, 127],
				[128, 132],
				[133, 137],
				[138, 142],
				[143, 147],
				[148, 152],
				[153, 157],
				[158, 162],
				[163, 167],
			];
			speed = Math.round(speed);
			var picIndex = 0;
			for (var i = 0, il = windLevel.length; i < il; i++) {
				var ele = windLevel[i];
				if (speed >= ele[0] && speed <= ele[1]) {
					picIndex = i;
				}
			}
			picIndex = picIndex == 0 ? 1 : picIndex;
			return picIndex;
		},
		// AIS点位上图
		addAISPoint(mmsi) {
			request({
				//url: process.env.VUE_APP_FLEET_API + `/gateway/voyage/v1/ais/shipdt/current/track`,
				url: process.env.VUE_APP_FLEET_API + `/gateway/voyage/v1/ais/cmes/high/data`,
				//url: `http://api.fltins.com/gateway/voyage/v1/ais/shipdt/track?mmsi=${mmsi}&days=1`,
				// url: `https://api.fltins.com/gateway/voyage/v1/ais/shipdt/track?mmsi=357928000&days=7`,
				method: 'GET',
				body: {
					mmsi: mmsi,
					startDate: mapobj.formatDateTime(new Date().getTime() - 1000 * 60 * 60 * 24 * 15),
					endDate: mapobj.formatDateTime(new Date()),
					//days: 7,
				},
				header: {
					Authorization: 'Bearer ' + getToken(),
				},
			})
				.then((res) => {
					//console.log(res);
					if (res && res.code == 200) {
						this.addAISPointLayer([
							{
								type: 'Feature',
								geometry: {
									type: 'Point',
									coordinates: [res.data.current.lon, res.data.current.lat],
								},
								properties: {
									cog: res.data.current.course,
								},
							},
						]);
					} else {
						this.$message.error('获取AIS数据失败');
					}
				})
				.catch((e) => {
					this.$message.error('获取AIS数据失败');
				});
		},
		addAllLineByWpt(pointData, active, speed) {
			let features = [];
			let weatherPoint = false;
			// 大圆航线数据
			let activeLine = 0;
			for (let x = 0; x < pointData.length; x++) {
				// 根据linenum区分pointData
				if (active && active.rowIndex == pointData[x].rowIndex) {
					let WPTList = pointData[x].WPTList;
					console.log(WPTList);
					let speed1 = speed ? speed : parseFloat(pointData[x].Summary.speed);
					console.log(speed);
					weatherPoint = mapobj.getWeatherPointInfo(WPTList, speed1);
					console.log(weatherPoint);
				} else {
					let WPTList = JSON.parse(JSON.stringify(pointData[x].WPTList));
					mapobj.get_wptlist_for_circle(WPTList);
					let Point = [];
					WPTList.forEach((element) => {
						Point.push([element.Lon, element.Lat]);
					});
					features.push({
						type: 'Feature',
						properties: {
							lineWidth: 1.5,
							linecolor: '#474747',
							dasharray: activeLine % 2 ? [2, 2] : [1, 0], // 偶数为实现 奇数为虚线
						},
						geometry: {
							type: 'LineString',
							coordinates: Point,
						},
					});
					activeLine++;
				}
			}
			setTimeout(() => {
				if (weatherPoint) {
					this.getWeatherData(weatherPoint, (points) => {
						for (let index = 0; index < weatherPoint.length - 1; index++) {
							let pointsElement = points.find((res) => {
								return res.lng == weatherPoint[index].lng && weatherPoint[index].lat == res.lat;
							});
							const lineStar = weatherPoint[index];
							const lineEnd = weatherPoint[index + 1];
							if (Math.abs(lineStar.lng) > 180) {
								if (lineStar.lng > 0) {
									lineStar.lng -= 360;
								} else {
									lineStar.lng += 360;
								}
							}
							if (Math.abs(lineEnd.lng) > 180) {
								if (lineEnd.lng > 0) {
									lineEnd.lng -= 360;
								} else {
									lineEnd.lng += 360;
								}
							}
							if (index % 3 == 0 && pointsElement) {
								features.push({
									type: 'Feature',
									properties: {
										...pointsElement,
										img: pointsElement ? this.wind_direction_img(pointsElement.windSpeed) : 1,
									},
									geometry: {
										type: 'Point',
										coordinates: [lineStar.lng, lineStar.lat],
									},
								});
							}
							let lineColor = pointsElement
								? this.get_line_color_by_sigwaveheight(pointsElement.sigWaveHeight)
								: '#7AF4D8';
							if (new Date().getTime() >= lineStar.dataTime) {
								//lineColor = "#696969";
							}
							features.push({
								type: 'Feature',
								properties: {
									...pointsElement,
									lineWidth: 6,
									linecolor: lineColor,
									dasharray: [1, 0],
								},
								geometry: {
									type: 'LineString',
									coordinates: [
										[lineStar.lng, lineStar.lat],
										[lineEnd.lng, lineEnd.lat],
									],
								},
							});
						}

						this.addLineLayer(features);
						//console.log("🚀 ~ file: index.vue:68 ~ addLineByPoint ~ features:", features);
						this.addPointLayer(features);
					});
				} else {
					this.addLineLayer(features);
				}
			}, 500);

			//this.addLineLayer(features);
		},
		// 加载航线
		addLineByPoint(mmsi, selectNum) {
			// 节点数据 mapobj.pointData
			//console.log("🚀 ~ file: index.vue:41 ~ addLineByPoint ~ mapobj.pointData:", selectNum)
			if (!mapobj.lineNum) {
				this.clearLayer();
				//this.$parent.$parent.loading = false
				return;
			}
			setTimeout(() => {
				this.addAISPoint(mmsi);
				this.addAllLineByWpt(selectNum);
			}, 1000);
			return;
		},
		// 获取气象数据
		async getWeatherData(weatherPoint, cb) {
			let res = await mapobj.getWeatherInfoByPromise(weatherPoint);
			cb(res);
		},
		// 根据浪高渲染颜色
		get_line_color_by_sigwaveheight(h) {
			// var colors = [
			//     'rgba(222,235,255,1)'
			//     , 'rgba(161,199,255,1)'
			//     , 'rgba(10,166,255,1)'
			//     , 'rgba(0,0,255,1)'
			//     , 'rgba(0,0,158,1)'
			//     , 'rgba(136,0,136,1)'
			//     , 'rgba(213,0,136,1)'
			//     , 'rgba(255,0,0,1)'
			// ];
			var colors = ['#7AF4D8', '#7AF4D8', '#7AF4D8', '#7AF4D8', '#CEFF85', '#CEFF85', '#FFE37F', '#FFE37F', '#FF7F7F'];
			var color = colors[0];
			if (parseInt(h)) {
				if (h < 0) {
					h = 0;
				}
				if (h > 8) {
					h = 8;
				}
				color = colors[parseInt(h)];
			}
			//console.log("🚀 ~ file: index.vue:116 ~ get_line_color_by_sigwaveheight ~ color:", color)
			return color;
		},
		// 添加线图层
		addLineLayer(features) {
			console.log('🚀 ~ file: index.vue:121 ~ addLayer ~ features:', features);
			if (!features.length) {
				this.clearLayer();
				//this.$parent.$parent.loading = false
				return;
			}
			const bound = turf.bbox(mapobj.pointData);
			this.map.fitBounds(bound);

			// 获取指定ID的图层对象
			if (this.map.getLayer(line_ID)) {
				this.map.getSource(line_ID).setData({
					type: 'FeatureCollection',
					features: features,
				});
			} else {
				this.map.addSource(line_ID, {
					type: 'geojson',
					data: {
						type: 'FeatureCollection',
						features: features,
					},
				});
				let layer_line = {
					id: line_ID,
					type: 'line',
					source: line_ID,
					filter: ['all', ['==', '$type', 'LineString']],
					layout: {
						'line-join': 'round',
						'line-cap': 'round',
					},
					paint: {
						'line-color': ['get', 'linecolor'],
						'line-width': ['get', 'lineWidth'],
						'line-dasharray': ['get', 'dasharray'],
						// "line-dasharray": ["case",
						//     ["==", ["get", "index"], 1], // 判断 index 属性是否等于 1
						//     [1, 0], // 等于 1 为实线样式
						//     [2, 2] // 否则为虚线样式
						// ]
						// "line-dasharray": [
						//     "case",
						//     ["% 2", ["get", "index"]],
						//     ["literal", [2, 2]] // 实线
						//     ["literal", [1, 0]] // 实线
						// ]
					},
				};
				this.map.addLayer(layer_line);
			}
			if (this.map.getLayer('point-ais-layer')) {
				this.map.moveLayer(line_ID, 'point-ais-layer');
			}
		},
		// 添加点图层
		addAISPointLayer(features) {
			// 获取指定ID的图层对象
			if (this.map.getLayer('point-ais-layer')) {
				this.map.getSource('point-ais-layer').setData({
					type: 'FeatureCollection',
					features: features,
				});
			} else {
				// 添加一个带旋转的图标
				this.map.addLayer({
					id: 'point-ais-layer',
					type: 'symbol',
					source: {
						type: 'geojson',
						data: {
							type: 'FeatureCollection',
							features: features,
						},
					},
					layout: {
						'icon-image': 'aisnoservice',
						'icon-size': 0.25,
						'icon-rotate': ['get', 'cog'],
					},
				});
			}
		},
		// 添加点图层
		addPointLayer(features) {
			if (!features.length) {
				this.clearLayer();
				//this.$parent.$parent.loading = false
				return;
			}
			// 获取指定ID的图层对象
			if (this.map.getLayer(point_ID)) {
				this.map.getSource(point_ID).setData({
					type: 'FeatureCollection',
					features: features,
				});
				this.map.getSource(lstIconLayerId).setData({
					type: 'FeatureCollection',
					features: features,
				});
			} else {
				// 添加一个带旋转的图标
				this.map.addLayer({
					id: point_ID,
					type: 'symbol',
					filter: ['all', ['==', '$type', 'Point']],
					source: {
						type: 'geojson',
						data: {
							type: 'FeatureCollection',
							features: features,
						},
					},
					layout: {
						'icon-image': 'x{img}',
						'icon-size': 0.5,
						'icon-anchor': 'bottom-left',
						'icon-rotate': ['get', 'windDir'],
						'icon-ignore-placement': true,
					},
				});
				var layer_points = {
					id: lstIconLayerId,
					type: 'circle',
					source: {
						type: 'geojson',
						data: {
							type: 'FeatureCollection',
							features: features,
						},
					},
					filter: ['all', ['==', '$type', 'Point']],
					paint: {
						'circle-radius': {
							base: 3,
							stops: [
								[4, 3],
								[8, 4],
							],
						},
						'circle-color': '#fff',
						//'circle-opacity': 0,
						'circle-stroke-color': 'black',
						'circle-stroke-width': 1,
					},
				};
				this.map.addLayer(layer_points); //关联bottom
			}
		},
		// 清空图层
		clearLayer() {
			[line_ID, point_ID, 'point-ais-layer', lstIconLayerId].forEach((e) => {
				if (this.map.getLayer(e)) {
					this.map.getSource(e).setData({
						type: 'FeatureCollection',
						features: [],
					});
				}
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.content {
	width: 100%;
	height: 100%;

	#mapboxMap {
		width: 100%;
		height: 100%;
	}
}
</style>
